.App {
  height: 100%;
}

.align-right {
  text-align: right;
}

.align-left {
  text-align: left;
}

.card {
  border: 1px #343434 solid;
  max-width: 80%;
}

.card:hover {
  cursor: pointer;
  -webkit-transform: translateX(-0.1rem) translateY(-0.1rem);
  transform: translateX(-0.1rem) translateY(-0.1rem);
  transition: 0.2s ease;
}

.card .card-header {
  border-bottom: 1.5px #fa8072 dashed;
  margin-top: 1rem;
}

.card .card-content {
  color: indianred;
}

.top-bar {
  padding: 1rem 2rem;
  margin: 0rem;
}

.nav {
  padding: 0.5rem;
  max-width: 300px;
}

.nav .nav-item {
  margin: 0rem 1rem;
  min-width: 2rem;
}

.main-display {
  padding: 2rem 4rem;
  /* background-color: #ece7e5; */
  background-color: #fff8f8;
  height: 100%;
}

.dashboard .status {
  padding: 4rem;
}

a .fa-github-alt {
  font-size: 20px;
}

a .fa-envelope {
  font-size: 20px;
}

a {
  text-decoration: none;
  color: indianred;
}

button {
  margin-top: 0px;
}

html {
  /* background-color: #fff8f8; */
  background-color: #ece7e5;
}

@media (max-width: 400px) {
  .nav {
    max-width: 100vw;
    padding: 1rem;
  }

  .nav .nav-item {
    width: 10px;
    text-align: center;
  }

  .main-display {
    flex-direction: column;
  }

  .card {
    max-width: 100%;
  }
}
